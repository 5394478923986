import * as React from "react";
import downloadAndZip from "../../util/downloadAndZip";
import { MediaDialogue } from "./MediaDialogue";
import MediaGrid from "./Grid";
import {
  Button,
  Grid,
  CircularProgress,
  TextField,
  ButtonGroup,
} from "@material-ui/core";
import SortableGrid from "./SortableGrid";
import ProfileCtx from "../../util/ProfileContext";
import { Revision, Media } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import { YZButton } from "@yardzen-inc/react-common";
// import FullScreenDesignDisplay from "./FullscreenCarousel";
type SelectedMedia = { [key: string]: Media };

export interface MediaGridProps {
  noDownload?: boolean;
  noVisibilityControlls?: boolean;
  userId: string;
  fileTag?: string;
  oneColumn?: boolean;
  permissions: Object;
  revision?: Revision;
  media: Media[];
  mediaToIncludeInDownloadAll?: Media[];
  onDeleteComplete(media: Media[]): any;
  onUploadComplete(): any;
  selectForClientFeedback?: boolean;
  onSendDesignToClient?: any;
  clientRecord?: any;
  showAnnotationSelection?: boolean;
  annotatedMediaCount?: number;
  revisionId?: string;
  onSortStart?: () => void;
  onSortEnd?: (sortedMedia: Media[]) => void;
  onSortAlphabetically?: () => void;
  noShare?: boolean;
  noDelete?: boolean;
  selectForAnnotation?: (media: string[]) => void;
  unShare?: (mediaToUnShare: Media[]) => void;
  toggleAnnotationSelection?: () => void;
}

const SpinnerAdornment = () => (
  <CircularProgress size={15} style={{ marginLeft: 5 }} />
);

export default function Index(props: MediaGridProps) {
  const { fileTag, media, userId } = props;
  const profile = React.useContext(ProfileCtx);
  const [inSortMode, setInSortMode] = React.useState<boolean>(false);
  const [mediaFiltered, setMediaFiltered]: [Media[], CallableFunction] =
    React.useState([]);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalSelectedIndex, setModalSelectedIndex] = React.useState(0);
  const [selectedMedia, setSelectedMedia] = React.useState<SelectedMedia>({});
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [downloadInProgress, setDownloadInProgress] = React.useState(false);
  const [fileNameHolding, setFileNameHolding] = React.useState({});
  const [isSharing, setIsSharing] = React.useState(false);
  const [sharedURL, setSharedURL] = React.useState<null | string>(null);
  const [addingAnnotatorSlides, setAddingAnnotatorSlides] =
    React.useState<boolean>(false);
  const collator = new Intl.Collator("en-US", {
    numeric: true,
    sensitivity: "base",
  });

  React.useEffect(() => {
    if (inSortMode && props.onSortStart) {
      props.onSortStart();
    }
  }, [inSortMode]);

  React.useEffect(() => {
    if (addingAnnotatorSlides) {
      setAddingAnnotatorSlides(false);
      setSelectedMedia({});
    }
    return () => {
      setAddingAnnotatorSlides(false);
      setInSortMode(false);
    };
  }, [props.revision?.id]);

  React.useEffect(() => {
    if (!media.length) {
      return setMediaFiltered([]);
    }

    if (!fileTag) return setMediaFiltered(media);

    const filtered: Media[] = [];

    media.forEach((record: Media) => {
      if (record["tag"] && record["tag"] === fileTag) {
        return filtered.push(record);
      }

      if (!record["tag"] && fileTag === "property") {
        return filtered.push(record);
      }

      return false;
    });

    setMediaFiltered(filtered);

    return () => {
      setSelectedMedia({});
    };
  }, [fileTag, media]);

  const onViewClick = (index: number) => {
    setModalSelectedIndex(index);
    setIsModalVisible(true);
  };

  const onItemSelectChange = (media: Media, checked: boolean) => {
    if (!checked) {
      let selectedMediaCopy = Object.assign({}, selectedMedia);
      delete selectedMediaCopy[media.id];
      return setSelectedMedia(selectedMediaCopy);
    }

    const selectedMediaCopy = Object.assign({}, selectedMedia, {
      [media.id]: media,
    });

    return setSelectedMedia(selectedMediaCopy);
  };

  function toggleSelectAll() {
    if (mediaFiltered.length === Object.keys(selectedMedia).length) {
      return setSelectedMedia({});
    }
    const select = {};

    mediaFiltered.forEach((med) => {
      // @ts-ignore
      select[med.id] = med;
    });

    setSelectedMedia(select);
  }

  async function shareSelectedMedia() {
    setIsSharing(true);
    const mediaArr: Media[] = Object.values(selectedMedia);

    const URL: string | boolean = await Media.makePublic(
      mediaArr,
      props.userId
    );
    setIsSharing(false);
    if (typeof URL === "string") {
      setSharedURL(URL);
    }
    clearSelected();
  }

  function clearSelected() {
    setSelectedMedia({});
  }

  const onOnModalCloseClick = () => {
    setIsModalVisible(false);
  };

  const objectAsArray = (o: Object): Array<any> => {
    // @ts-ignore
    return Object.keys(o).map((i) => o[i]);
  };

  const onDeleteClick = async () => {
    if (!confirm("Really delete selected files?")) {
      return;
    }
    setDeleteInProgress(true);

    const mediaToDelete: Media[] = objectAsArray(selectedMedia);

    await Promise.all(mediaToDelete.map((item) => Media.delete(item.id)));
    props.onDeleteComplete(mediaToDelete);

    setDeleteInProgress(false);
  };

  const onDownloadClick = async () => {
    setDownloadInProgress(true);

    if (Object.keys(selectedMedia).length) {
      var mediaRecords = objectAsArray(selectedMedia) as Media[];
    } else {
      var mediaRecords = [
        ...media,
        ...(props.mediaToIncludeInDownloadAll || []),
      ];
    }

    await downloadAndZip(mediaRecords as any[], `${userId}.zip`);

    setTimeout(() => {
      setDownloadInProgress(false);
    }, 1000);
  };

  const updateFileName = async (mediaRecord: any) => {
    const originalName: string = mediaRecord["originalFileName"]?.trim();
    // @ts-ignore
    let newFileName: string = fileNameHolding[mediaRecord.id]?.trim();

    const split = originalName.split(".");

    if (originalName.split.length > 1) {
      const ext = split.pop();

      const newSplit = newFileName.split(".");

      if (newFileName.length > 1 && newSplit.pop() !== ext) {
        newFileName = newFileName + "." + ext;
      }
    }

    if (originalName === newFileName || newFileName === undefined) {
      let selectedMediaCopy = Object.assign({}, selectedMedia);
      delete selectedMediaCopy[mediaRecord.id];
      return setSelectedMedia(selectedMediaCopy);
    }

    // no janky Media class methods here 👀
    await firebase
      .firestore()
      .collection("media")
      .doc(mediaRecord.id)
      .update({ updatedFileName: newFileName });

    /*
    we need this because of the way the grid is built

    we have to trick the component into rerendering by copying the
    media array passed down to this component through props
    the media record here is a reference to the one in that array,
    so setting it here will have the desired effect when
    the component rerenders
    */
    mediaRecord.updatedFileName = newFileName;

    props.onUploadComplete();
  };

  const handleFileNameChange = (mediaRecord: any, value: string) => {
    setFileNameHolding({ ...fileNameHolding, [mediaRecord.id]: value });
  };

  function unShare() {
    const mediaArr: Media[] = Object.values(selectedMedia);
    if (props.unShare !== undefined) {
      props.unShare(mediaArr);
    }
    setSelectedMedia({});
  }

  function runOnSortEnd(media: Media[]) {
    setMediaFiltered(media);
    if (props.onSortEnd) {
      props.onSortEnd(media);
    }
  }

  function renderGrid() {
    if (mediaFiltered) {
      if (inSortMode && props.revisionId && props.onSortEnd) {
        return (
          <SortableGrid
            revisionId={props.revisionId}
            projectId={props.userId}
            media={mediaFiltered}
            onSortEnd={runOnSortEnd}
          />
        );
      }
      return (
        <MediaGrid
          noDownload={props.noDownload}
          noVisibiliyControlls={props.noVisibilityControlls}
          oneColumn={props.oneColumn}
          media={mediaFiltered}
          onViewClick={onViewClick}
          onItemSelectChange={onItemSelectChange}
          noCheck={props.showAnnotationSelection}
          permissions={props.permissions}
          selectedMedia={selectedMedia}
          fileNameHolding={fileNameHolding}
          handleFileNameChange={handleFileNameChange}
          submitFileName={updateFileName}
          selectForClientFeedback={
            !!props.selectForClientFeedback ? true : false
          }
        />
      );
    }
    return <></>;
  }

  return (
    <div style={{ marginTop: 25 }}>
      <div style={{ width: "80%", margin: "auto" }}>
        <Grid container>
          <Grid item>
            <ButtonGroup variant="outlined" style={{ margin: "8px" }}>
              {!!props.onSortEnd && (
                <Button onClick={() => setInSortMode(!inSortMode)}>
                  {inSortMode ? "Done sorting" : "Edit sorting"}
                </Button>
              )}
              {inSortMode && !!props.onSortEnd && (
                <Button
                  onClick={() => {
                    if (!!props.onSortEnd) {
                      sortByName();
                    }
                  }}
                >
                  Sort Alphabetically
                </Button>
              )}
              <Button onClick={toggleSelectAll}>
                {media.length === Object.keys(selectedMedia).length
                  ? "Deselect All"
                  : "Select All"}
              </Button>
              {!props.noDownload && (
                <Button onClick={onDownloadClick} disabled={downloadInProgress}>
                  Download {downloadInProgress && <SpinnerAdornment />}
                </Button>
              )}
              {!!profile &&
                // @ts-ignore
                profile["isEmployee"] &&
                !!props.toggleAnnotationSelection && (
                  <Button onClick={props.toggleAnnotationSelection as any}>
                    {!props.showAnnotationSelection
                      ? `Show media selected for annotation (${
                          props.annotatedMediaCount === undefined
                            ? ""
                            : props.annotatedMediaCount
                        })`
                      : "Show all"}
                  </Button>
                )}
              {
                // @ts-ignore
                props.permissions["media"]["access"] === "update" &&
                  !props.showAnnotationSelection &&
                  !!Object.keys(selectedMedia).length &&
                  !props.noDelete && (
                    <Button onClick={onDeleteClick} disabled={deleteInProgress}>
                      Delete {deleteInProgress && <SpinnerAdornment />}
                    </Button>
                  )
              }
              {!!profile &&
                !props.showAnnotationSelection &&
                // @ts-ignore
                profile["isEmployee"] &&
                !!Object.keys(selectedMedia).length &&
                !props.noShare && (
                  <Button onClick={shareSelectedMedia}>
                    Share {isSharing && <SpinnerAdornment />}
                  </Button>
                )}
              {!!profile &&
                // @ts-ignore
                profile["isEmployee"] &&
                !props.showAnnotationSelection &&
                !!Object.keys(selectedMedia).length &&
                props.noShare &&
                props.unShare !== undefined && (
                  <Button onClick={unShare}>
                    Un Share {isSharing && <SpinnerAdornment />}
                  </Button>
                )}
              {!!profile &&
                // @ts-ignore
                profile["isEmployee"] &&
                !!Object.keys(selectedMedia).length &&
                !props.showAnnotationSelection &&
                !!props.selectForAnnotation && (
                  <Button
                    disabled={addingAnnotatorSlides}
                    onClick={async () => {
                      setAddingAnnotatorSlides(true);
                      await (
                        props.selectForAnnotation as (media: string[]) => void
                      )(Object.keys(selectedMedia));
                      setAddingAnnotatorSlides(false);
                    }}
                  >
                    {!addingAnnotatorSlides ? (
                      "Select for Annotation"
                    ) : (
                      <>
                        Adding Slides
                        <div
                          style={{
                            display: "inline-block",
                            paddingLeft: "1rem",
                          }}
                        />
                        <CircularProgress size={15} />
                      </>
                    )}
                  </Button>
                )}
              {!!profile &&
                // @ts-ignore
                profile["isEmployee"] &&
                props.showAnnotationSelection &&
                !!props.selectForAnnotation && (
                  <Button
                    disabled={addingAnnotatorSlides}
                    onClick={() => {
                      setAddingAnnotatorSlides(true);
                      (props.selectForAnnotation as (media: string[]) => void)(
                        []
                      );
                    }}
                  >
                    {!addingAnnotatorSlides ? (
                      "Remove all"
                    ) : (
                      <>
                        Adding Slides
                        <div
                          style={{
                            display: "inline-block",
                            paddingLeft: "1rem",
                          }}
                        />
                        <CircularProgress size={15} />
                      </>
                    )}
                  </Button>
                )}
            </ButtonGroup>
          </Grid>
          <Grid style={{ marginLeft: "auto", marginRight: "1rem" }} item>
            {props.selectForClientFeedback &&
              !!props.onSendDesignToClient &&
              !Object.keys(selectedMedia).length &&
              !inSortMode &&
              props.clientRecord["featureFlags"] &&
              props.clientRecord["featureFlags"].includes(
                "postOnboardUiBeta"
              ) && (
                <YZButton
                  onClick={() => props.onSendDesignToClient(props.media)}
                  variant="contained"
                >
                  write blurb & send
                </YZButton>
              )}
          </Grid>
        </Grid>
        {sharedURL && (
          <TextField
            InputProps={{ readOnly: true }}
            value={sharedURL}
            variant="outlined"
            style={{ marginTop: "1.25rem" }}
            label="Shareable URL"
          />
        )}
      </div>
      {renderGrid()}
      {/* <FullScreenDesignDisplay
        open={isModalVisible}
        media={mediaFiltered}
        onClose={onOnModalCloseClick}
        selectedIndex={modalSelectedIndex}
      /> */}
      <MediaDialogue
        selectedIndex={modalSelectedIndex}
        media={mediaFiltered}
        showDialogue={isModalVisible}
        onClose={onOnModalCloseClick}
      />
    </div>
  );

  function sortByName() {
    const mediaToSort = mediaFiltered;
    const oldMediaIds = media.map((m) => m.id);
    const sortedMedia = mediaToSort.sort((a, b) =>
      collator.compare(a.originalFileName, b.originalFileName)
    );
    const sortedIds = sortedMedia.map((m) => m.id);
    if (oldMediaIds === sortedIds) {
      return;
    }

    if (props.onSortAlphabetically) props.onSortAlphabetically();

    return runOnSortEnd(sortedMedia);
  }
}

import * as React from "react";
import firebase from "firebase/compat/app";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { PageComponentProps } from "./CurrentJobPageMap";
import { DesignAssignment, Media, Revision } from "@yardzen-inc/models";
import DesignerDeliverableUploadArea from "./DesignerDeliverableUploadArea";
import DesignerProgressSlider from "./DesignerProgressSlider";
import { DesignerSummaryInfo } from "./DesignerSummaryInfo";
import CadDeliverableUploadArea from "./CadDeliverableUploadArea";
import ChecklistUpload from "./ChecklistUpload";
import WorkingFilesUpload from "./WorkingFilesUpload";
import SubmitButtonBar from "./SubmitButtonBar";
import GenericConfirm, {
  GenericConfirmProps,
} from "../Components/GenericConfirm";
import genericLiisaNotification from "../util/genericLiisaNotification";
import makeDesignAssignmentSlug from "../ClientDetail/Revisions/util/makeDesignAssignmentSlug";
import makeLiisaLink from "../util/makeLiisaLink";
import DesignerSummaryDownloads from "./DesignerSummaryDownloads";
import { DesignBriefMeta } from "../Components/designBrief/util";
import isOldBotanical from "../ClientDetail/Revisions/util/isOldBotanical";
import environmentConstants from "../ConstantValues/environmentConstants";
import { liisaErrorAlert } from "../util/genericAlert";
import useDssStatesOrderedMap from "../util/selfAssign/useDssStatesOrderedMap";
import { useQuery } from "@yardzen-inc/graphql";
import { YZTypography } from "@yardzen-inc/react-common";
import { ProjectFunctions } from "@yardzen-inc/util";
import { ProjectTagIconDisplay } from "../Components/ProjectTagIconDisplay";
import { submitDesignAssignment } from "../api/vanillaPangaeaClient";
import { useEffect, useMemo, useState } from "react";
import GenericSnackBar from "../Components/GenericSnackBar";
import { QUERY_SELECTION_FOR_BUDGET_QA } from "../../src/graphql/budgetQAQueries";
import { isStarterPackage } from "../util/isStarterPackage";
import { AgentQualification } from "../ConstantValues/AgentConstants";
import {
  sendToClientNotifications,
  sendToClientRpc,
} from "../common/revision-submit";
import MessageModal, {
  DeliverableRevisionIdentifier,
} from "../ClientDetail/Revisions/MessageModal";
import { UserCtx } from "../util/UserContext";
import { PackageType } from "../Interfaces";
import {
  ELEMENTS,
  MATERIALS,
  MediaInclusion,
  PLANTS,
  basePropertyObject,
} from "../ClientDetail/Media/IncludeMediaInSharedLink";
import { createSharedMedia } from "../ClientDetail/Media/createSharedMedia";
import { useGetBudgetQADataForSharedMedia } from "../ClientDetail/Media/util/useGetBudgetQADataForSharedMedia";

interface Props {
  assignment: DesignAssignment;
  hasNewDesignBrief?: boolean;
}

const useStyles = makeStyles((theme) => ({
  contentBox: {
    padding: "1.5rem 1rem",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    marginBottom: "0.5rem",
  },
  iconDisplay: {
    justifyContent: "flex-start",
  },
}));

const collator = new Intl.Collator("en-US", {
  numeric: true,
  sensitivity: "base",
});

export const DesignSummaryPageV2 = (props: PageComponentProps & Props) => {
  const getBudgetQAData = useGetBudgetQADataForSharedMedia({
    designAssignmentId: props.assignment.id,
    designAssignmentIdIsLoading: false,
    profile: props.profile,
  });
  const user = React.useContext(UserCtx);
  const revisionCanBeShipped = projectIsShippeable();
  const classes = useStyles();
  const dssStates = useDssStatesOrderedMap();
  const [lastUpload, setLastUpload] = useState<Media | null>(null);
  const [missingMessage, setMissingMessage] = useState<string | null>(null);
  const [subButtonStatus, setSubButtonStatus] = useState<boolean | "loading">(
    false
  );
  const [blurbModalOpen, setBlurbModalOpen] = useState(false);
  const [revision, setRevision] = useState<Revision | null>(null);
  const [shipToClientOptions, setShipToClientOptions] = useState<{
    sendToDesigns: boolean;
    notifyClient: boolean;
    allowAnnotations: boolean;
    revisionSelect: DeliverableRevisionIdentifier;
    blurb?: string | undefined;
  }>({
    sendToDesigns: false,
    notifyClient: false,
    allowAnnotations: false,
    revisionSelect: "conceptual",
    blurb: undefined,
  });

  const [genConfirmProps, setGenConfirmProps] =
    useState<GenericConfirmProps | null>(null);

  const [error, setError] = useState<null | string>(null);

  const { data: selectionData } = useQuery(QUERY_SELECTION_FOR_BUDGET_QA, {
    fetchPolicy: "network-only",
    variables: {
      id: props.assignment?.id,
    },
  });

  useEffect(onUpload, [lastUpload, selectionData]);

  useEffect(() => {
    verifyUploads();
  }, [selectionData]);

  useEffect(() => {
    async function getRevision() {
      const rev = await props.assignment.getRevision();
      setRevision(rev);
    }

    if (props.assignment && !revision) getRevision();
  }, [props.assignment]);

  useEffect(() => {
    setGenConfirmProps({
      ...getGenericConfirmConstantProps(),
      body: genConfirmProps?.body,
      open: !!genConfirmProps?.open,
      submitting: genConfirmProps?.submitting,
    });
  }, [shipToClientOptions]);

  const withCad = useMemo(useCad, [props.assignment]);
  const withChecklist = useMemo(useChecklist, [props.assignment]);

  const isVIP = ProjectFunctions.isVIP(props.project);
  const isPremium = !!props.project.isPremium;

  return (
    <Box p={3}>
      {genConfirmProps && <GenericConfirm {...genConfirmProps} />}
      <Box className={classes.contentBox}>
        <Grid container>
          <Grid item md={3}>
            <Box mb={3}>
              <YZTypography variant="h5" type="serif">
                Project Details
              </YZTypography>
              <ProjectTagIconDisplay
                isVIP={isVIP}
                isPremium={isPremium}
                // designers do not need to see when a project is expedited
                className={classes.iconDisplay}
              />
            </Box>
          </Grid>
          <Grid item md={9}>
            <DesignerSummaryInfo {...props} />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.contentBox}>
        <DesignerSummaryDownloads
          userId={props.profile.userId}
          lastName={props.profile.lastName as string}
          street={props.profile.street as string}
          version={props.assignment.type}
        />
      </Box>
      <Box className={classes.contentBox}>
        <DesignerProgressSlider
          assignment={props.assignment as DesignAssignment}
        />
      </Box>
      {withCad && (
        <>
          <CadDeliverableUploadArea
            userId={props.profile.id}
            revisionId={props.assignment.revisionId}
            onUpload={handleUpload}
          />
        </>
      )}
      {!!(
        withChecklist &&
        !(props.project.useAutomatedDesignBrief || props.hasNewDesignBrief)
      ) && (
        <Box className={classes.contentBox}>
          <ChecklistUpload
            onUpload={handleUpload}
            userId={props.profile.id}
            revisionId={props.assignment.revisionId}
          />
        </Box>
      )}
      <Box className={classes.contentBox}>
        <WorkingFilesUpload
          userId={props.profile.id}
          revisionId={props.assignment.revisionId}
        />
      </Box>
      <Box className={classes.contentBox}>
        <DesignerDeliverableUploadArea
          onUpload={handleUpload}
          userId={props.profile.id}
          revision={revision}
          agentCanShip={revisionCanBeShipped}
          sortByName={sortByName}
        />
      </Box>
      <SubmitButtonBar
        status={subButtonStatus}
        message={missingMessage}
        agentCanShip={revisionCanBeShipped}
        onClick={() => {
          if (revisionCanBeShipped) setBlurbModalOpen(true);
          else
            setGenConfirmProps({
              ...getGenericConfirmConstantProps(),
              open: true,
              submitting: false,
            });
        }}
      />
      {!!blurbModalOpen && revision && revisionCanBeShipped && (
        <MessageModal
          // @ts-ignore
          sendToClient={(
            sendToDesigns: boolean,
            revisionSelect: DeliverableRevisionIdentifier,
            notifyClient: boolean,
            allowAnnotations: boolean,
            blurb?: string | undefined
          ) => {
            setShipToClientOptions({
              notifyClient,
              sendToDesigns,
              allowAnnotations,
              revisionSelect,
              blurb,
            });
            setGenConfirmProps({
              ...getGenericConfirmConstantProps(),
              open: true,
              submitting: false,
            });
          }}
          open={blurbModalOpen}
          onClose={() => setBlurbModalOpen(false)}
          conceptualRevisionId={props.profile.conceptualRevision || undefined}
          finalRevisionId={props.profile.finalRevision || undefined}
          v3RevisionId={props.profile.v3Revision || undefined}
          clientRecord={props.profile}
          currentRevision={revision}
          defaultSettings={{
            allowAnnotations: true,
            notifyClient: true,
            sendToDesigns: true,
          }}
        />
      )}
      <GenericSnackBar
        orientation={{
          vertical: "top",
          horizontal: "right",
        }}
        in={error !== null}
        message={error || ""}
        variant="error"
        onClose={() => setError(null)}
        autoHide={false}
      />
    </Box>
  );

  function isBotanical(): boolean {
    try {
      return props.profile.package?.toLowerCase() === "botanical";
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  function isNewBotanical(): boolean {
    const BOTANICAL = "botanical";
    if (props.profile.package?.toLowerCase() === BOTANICAL) {
      return !isOldBotanical(BOTANICAL, props.profile.createdAt);
    }
    return false;
  }

  function useCad(): React.ReactNode | null {
    if (isBotanical() && !isNewBotanical()) {
      return true;
    }

    if (isStarterPackage(props.profile.package)) {
      return false;
    }

    if (props.assignment.type === "correction") {
      return props.assignment.correctionFor !== "v1";
    }

    return props.assignment.type !== "v1";
  }

  function useChecklist() {
    return (
      props.assignment.type === "v1" ||
      (props.assignment.type === "correction" &&
        props.assignment.correctionFor === "v1")
    );
  }

  async function createShareLink(revisionId: string) {
    const inclusionObject: MediaInclusion = {
      profileId: props.profile.id,
      revision: {
        id: revisionId,
        includedCads: ["cad-layout", "cad-landscape", "cad-legend"],
        includeGeneratedPDF: true,
      },
      property: { ...basePropertyObject },
      pdfList: {
        [PLANTS]: true,
        [MATERIALS]: true,
        [ELEMENTS]: true,
      },
    };

    return createSharedMedia(inclusionObject, getBudgetQAData);
  }

  async function submit(): Promise<any> {
    let sharedMediaPromise;
    setGenConfirmProps({
      ...getGenericConfirmConstantProps(),
      body: "Please do not close or refresh the browser window while submitting.",
      open: true,
      submitting: true,
      onClose: () => null,
    });

    if (!dssStates) {
      throw new Error("NO DSS STATES FOUND");
    }
    try {
      await submitDesignAssignment({
        designAssignmentId: props.assignment.id,
        projectId: props.project.id,
        designerId: props.agent.id,
      });

      // Check if agent can ship, send assignment to client
      if (revisionCanBeShipped) {
        const revision = await props.assignment.getRevision();
        const media = await revision.getMediaForQA();

        // this pulls a ton of data, do in background
        sharedMediaPromise = createShareLink(revision.id);

        await sendToClientRpc(
          user,
          revision,
          media,
          shipToClientOptions.revisionSelect,
          shipToClientOptions.allowAnnotations,
          shipToClientOptions.blurb
        );

        await sendToClientNotifications(
          props.assignment.revisionId,
          props.profile,
          shipToClientOptions.revisionSelect,
          shipToClientOptions.sendToDesigns, // sendToDesigns
          shipToClientOptions.notifyClient, // notifyClient
          user
        );
      }
    } catch (error) {
      console.error(error);
      setError(
        `Unable to submit assignment with id: ${props.assignment.id}. An error occurred - ${error}`
      );
      setGenConfirmProps(null);
      return liisaErrorAlert(
        "Designer was unable to submit assignment in liisa",
        // @ts-ignore
        `Error Message: ${error.message}
        \nProject Id: ${props.project.id}
        \nProfile Id: ${props.profile.id}
        \n Designer Id: ${props.agent.id}
        \nActive Design Assignment Id: ${props.assignment.id}
        \nActive Revision Id: ${props.assignment.revisionId}`
      );
    }

    try {
      await genericLiisaNotification({
        message: `${
          process.env.REACT_APP_ENV === environmentConstants.DEVELOPMENT
            ? "TEST: Designer"
            : "Designer"
        } ${props.agent.firstName} ${props.agent.lastName} <${
          props.agent.email
        }>
      Submitted deliverables for assignment ${makeDesignAssignmentSlug(
        props.profile.lastName as string,
        props.profile.street as string,
        props.assignment.type
      )}

      ${makeLiisaLink(props.profile.id)}/deliverables
      `,
        icon: ":classical_building:",
      });
    } catch (err) {
      console.error("liisa notification failed");
      console.error(err);
      setError(`Unable to send notification to HQ. An error occurred - ${err}`);
    }

    try {
      await sharedMediaPromise;
    } catch (error) {
      console.error(error);
      setError(
        "Failed to create share link. Please try to recreate on the media tab -> shared media"
      );
    }

    alert("Submission Complete");
    window.location.reload();
  }

  async function verifyUploads(): Promise<boolean> {
    if (withCad) {
      if (
        !(await checkSnapForSingle(
          getQueryForRevision().where("tag", "==", "cad-layout")
        )) ||
        !(await checkSnapForSingle(
          getQueryForRevision().where("tag", "==", "cad-landscape")
        )) ||
        !(await checkSnapForSingle(
          getQueryForRevision().where("tag", "==", "cad-legend")
        )) ||
        !(await checkSnapForSingle(
          getQueryForRevision().where("tag", "==", "cad-dwg")
        ))
      ) {
        setMissingMessage("Please upload all cad drawings to continue");
        return false;
      }
    }

    if (withChecklist) {
      if (!props.project.useAutomatedDesignBrief && !props.hasNewDesignBrief) {
        if (
          !(await checkSnapForSingle(
            getQueryForRevision().where("tag", "==", "design-checklist")
          ))
        ) {
          setMissingMessage("Please upload the design checklist to continue");
          return false;
        }
      }
    }

    if (props.project.useAutomatedDesignBrief || props.hasNewDesignBrief) {
      const db = await firebase
        .firestore()
        .collection("projects")
        .doc(props.project.id)
        .collection("designBrief")
        .get();

      const incompleteDoc =
        db.docs.find((doc) => {
          const data = doc.data() ?? null;

          if (!(doc.id === "__meta") && !data.marked) {
            return true;
          }

          return false;
        }) ?? null;

      if (incompleteDoc) {
        const data = incompleteDoc.data() ?? null;
        setMissingMessage(`
              Please Sign off on all Design Brief items.

              Missing approval on: ${data?.label ?? ""} ${data.value}
            `);

        return false;
      }

      const metaSnap = await firebase
        .firestore()
        .collection("projects")
        .doc(props.project.id)
        .collection("designBrief")
        .doc("__meta")
        .get();

      const acks = (metaSnap.data() as DesignBriefMeta)?.acknowledges || {};

      const incompleteAck = Object.keys(acks).find((a) => {
        return !acks[a].value;
      });

      if (incompleteAck !== void 0) {
        setMissingMessage(`
              Please Sign off on all Design Brief items.

              Missing approval on: ${incompleteAck}
            `);

        return false;
      }
    }

    if (
      !(await checkSnapForSingle(
        getQueryForRevision().where("tag", "==", "deliverable")
      ))
    ) {
      setMissingMessage("Please upload design renders to continue");
      return false;
    }

    if (missingMessage) {
      setMissingMessage(null);
    }

    return true;
  }

  function getQueryForRevision(): firebase.firestore.Query {
    const query = firebase
      .firestore()
      .collection("media")
      .where("userId", "==", props.profile.id)
      .where("revisionId", "==", props.assignment.revisionId);

    return query;
  }

  function onUpload() {
    const _onUpload = async function () {
      setSubButtonStatus("loading");
      setSubButtonStatus(await verifyUploads());
    };

    const timeout = setTimeout(_onUpload, 100);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }

  async function checkSnapForSingle(
    snap: firebase.firestore.QuerySnapshot | firebase.firestore.Query
  ): Promise<boolean> {
    let _snap: firebase.firestore.QuerySnapshot;

    if (snap instanceof firebase.firestore.QuerySnapshot) {
      _snap = snap;
    } else {
      _snap = await snap.get();
    }

    return !_snap.empty;
  }

  function handleUpload(up: Media | null): void {
    setLastUpload(up);
  }

  function getGenericConfirmConstantProps(): {
    onSubmit: () => void;
    onClose: () => void;
    body: string;
    buttonText: string;
    checkLabel: string;
  } {
    return {
      onSubmit: submit as () => void,
      onClose: () => setGenConfirmProps(null),
      body: "Please review your uploaded materials and confirm that they meet Yardzen's standards before moving on",
      buttonText: "submit",
      checkLabel: "I'm finished",
    };
  }

  function projectIsShippeable() {
    const unshipablePackages: PackageType[] = [PackageType.UberPremium];
    const isVIP = props.project?.tags?.includes?.("VIP");
    const projectNeedsQA = (props.project as any)["needsRevisionQA"];
    const agentCanShip = !!props.agent.qualifications.find(
      (q) => q === AgentQualification.CanShipProject
    );
    const fullName = `${props.profile.firstName} ${props.profile.lastName}`;
    const isProDesign =
      fullName.toLocaleLowerCase().includes("(pro)") ||
      fullName.toLocaleLowerCase().includes("(d4p)");

    return (
      agentCanShip &&
      !unshipablePackages.includes(props.profile.package as PackageType) &&
      !isVIP &&
      !projectNeedsQA &&
      !isProDesign
    );
  }

  async function sortByName() {
    const revisionDeliverables = await firebase
      .firestore()
      .collection("media")
      .where("tag", "==", "deliverable")
      .where("revisionId", "==", props.assignment.revisionId)
      .get();

    const mediaToSort = await Promise.all(
      revisionDeliverables.docs.map((doc) => {
        return Media.createFromQuerySnapshot(doc);
      })
    );
    const sortedMedia = mediaToSort.sort((a, b) =>
      collator.compare(a.originalFileName, b.originalFileName)
    );

    await handleSort(sortedMedia);
  }

  async function handleSort(sortedMedia: Media[]) {
    await firebase
      .firestore()
      .runTransaction((t) => {
        const mediaRefs = sortedMedia.map((m) =>
          firebase.firestore().collection("media").doc(m.id)
        );

        return Promise.all(
          mediaRefs.map((ref, index) => {
            return new Promise<void>((resolve) => {
              t.get(ref).then((doc) => {
                if (!doc.exists) throw "Document does not exist!";
                t.update(ref, { sortedIndex: index });
                resolve();
              });
            });
          })
        );
      })
      .then(() => {
        console.log("SUCCESS");
      })
      .catch((e) => {
        console.error("sort transaction error: ", e);
        setError("Sort failed! Please try again or contact engineering");
      });
  }
};

export default DesignSummaryPageV2;

import * as React from "react";
import NewMediaGrid from "../Components/NewMediaGrid";
import FileUpload from "../Components/FileUpload";
import { Box, Button, Grid } from "@material-ui/core";
import { Media, Revision } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import sortMediaByName from "../util/sortMediaByName";
import GenericSnackBar from "../Components/GenericSnackBar";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import DashboardModal from "../Components/modals/ModalBase";

interface Props {
  userId: string;
  revision?: Revision | null;
  agentCanShip: boolean;
  onUpload: (m: Media | null) => void;
  sortByName: () => void;
}

const DESIGNER_RENDER_ANNOTATION_LIMIT = 10;

export const DesignerDeliverableUploadArea = ({
  userId,
  revision,
  agentCanShip,
  onUpload,
  sortByName,
}: Props) => {
  const [sortMode, setSortMode] = React.useState<boolean>(false);
  const [error, setError] = React.useState<false | string>(false);
  const [forAnnotations, setForAnnotations] = React.useState<string[]>([]);
  const [showAnnotationLimitModal, setShowAnnotationLimitModal] =
    React.useState(false);

  React.useEffect(() => {
    if (revision) {
      setForAnnotations(revision.annotationSlides || []);
    }
  }, [revision]);

  if (!revision) return null;

  return (
    <Grid container>
      <Grid item md={3}>
        <YZTypography type="serif" variant="h5">
          Renders and Deliverables
        </YZTypography>
      </Grid>
      <Grid item md={9}>
        {!!error && (
          <GenericSnackBar
            variant={"error"}
            message={error}
            onClose={() => setError(false)}
          />
        )}
        <Box
          py={1}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
          <YZButton
            size="small"
            variant="outlined"
            onClick={deleteAll}
            style={{ marginRight: "0.5rem" }}
          >
            Delete All
          </YZButton>
          <YZButton
            variant="outlined"
            size="small"
            onClick={() => setSortMode(!sortMode)}
            style={{ marginRight: "0.5rem" }}
          >
            {sortMode ? "Done sorting" : "Sort"}
          </YZButton>
          <YZButton
            variant="outlined"
            size="small"
            onClick={() => sortByName()}
          >
            Sort alphabetically
          </YZButton>
        </Box>
        <NewMediaGrid
          cardType={agentCanShip ? "checkable" : "readOnly"}
          checked={agentCanShip ? forAnnotations : []}
          toggleChecked={agentCanShip ? handleToggleAnnotation : undefined}
          noNoItems
          onUpdate={(meds: Media[] | null) => {
            if (!meds) {
              return onUpload(null);
            }

            onUpload(meds[0]);
          }}
          media={revision.id}
          sortingfunction={sortMediaByName}
          revisionId
          deletable
          userId={userId}
          onSort={handleSort}
          sortMode={sortMode}
        />
        <FileUpload
          clientUserId={userId}
          revisionId={revision.id}
          fileTag="deliverable"
          fileVisibility={{}}
          accept={["image/*", ".pdf"]}
        />
        <DashboardModal
          open={showAnnotationLimitModal}
          onClose={() => setShowAnnotationLimitModal(false)}
          animate
          ariaLabelledby="annotation-limit-modal"
        >
          <div>
            Can not select more than {DESIGNER_RENDER_ANNOTATION_LIMIT} renders
            for annotation, please remove one or more to add new ones.
          </div>
          <Button
            onClick={() => setShowAnnotationLimitModal(false)}
            variant="contained"
            color="secondary"
            style={{ marginTop: "10px" }}
          >
            Close
          </Button>
        </DashboardModal>
      </Grid>
    </Grid>
  );

  async function handleSort(sortedMedia: Media[]) {
    await firebase
      .firestore()
      .runTransaction((t) => {
        const mediaRefs = sortedMedia.map((m) =>
          firebase.firestore().collection("media").doc(m.id)
        );

        return Promise.all(
          mediaRefs.map((ref, index) => {
            return new Promise<void>((resolve) => {
              t.get(ref).then((doc) => {
                if (!doc.exists) throw "Document does not exist!";
                t.update(ref, { sortedIndex: index });
                resolve();
              });
            });
          })
        );
      })
      .then(() => {
        console.log("SUCCESS");
      })
      .catch((e) => {
        console.error("sort transaction error: ", e);
        setError("Sort failed! Please try again or contact engineering");
      });

    const sortedMediaIds = sortedMedia.map((m) => m.id);
    const validAnnotationSlides =
      revision?.annotationSlides?.filter((m) => sortedMediaIds.includes(m)) ||
      [];
    return firebase
      .firestore()
      .collection("revisions")
      .doc(revision?.id)
      .update({
        deliverables: sortedMediaIds,
        annotationSlides: validAnnotationSlides,
      })
      .finally(() => {
        console.log("sorted");
      });
  }

  async function handleToggleAnnotation(mediaId: string) {
    let updatedAnnotations = [...forAnnotations];
    if (forAnnotations.includes(mediaId)) {
      updatedAnnotations = forAnnotations.filter((m) => m !== mediaId);
    } else {
      if (forAnnotations.length >= DESIGNER_RENDER_ANNOTATION_LIMIT) {
        setShowAnnotationLimitModal(true);
        return;
      }
      updatedAnnotations = [...forAnnotations, mediaId];
    }

    setForAnnotations(updatedAnnotations);

    await firebase
      .firestore()
      .collection("revisions")
      .doc(revision?.id)
      .update({
        annotationSlides: updatedAnnotations,
      });
  }

  async function deleteAll(): Promise<any> {
    const all = await firebase
      .firestore()
      .collection("media")
      .where("revisionId", "==", revision?.id)
      .where("userId", "==", userId)
      .where("tag", "==", "deliverable")
      .get();

    if (all.empty) {
      return;
    }

    const batch = firebase.firestore().batch();

    for (let d of all.docs) {
      batch.delete(d.ref);
    }

    setForAnnotations([]);

    return batch.commit();
  }
};

export default DesignerDeliverableUploadArea;
